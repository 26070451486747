/**
 * Checks if a node/element exists
 * @param {any} value - The thing you want to check is not null or undefined, could be an Element, Variable, etc..
 * @returns {Boolean}
 */
function isSet(value){
    if (typeof(value) != 'undefined' && value != null) {
        return true;
    }else{
        return false; 
    }
}


exports.isSet = isSet