function checkStickyHeight(domNode){
    if(!domNode.classList.contains('is-sticky')){
        console.warn('No .is-sticky class on passed DOM Node.');
    }

    const nodeHeight = domNode.offsetHeight;
    const windowHeight = window.innerHeight;
    const siteHeaderHeight = document.getElementById('masthead').offsetHeight;
    const availableHeight = windowHeight - siteHeaderHeight;

    if(nodeHeight > availableHeight){
        domNode.classList.remove('is-sticky');
        return true
    }else{
        domNode.classList.add('is-sticky');
        return false
    }
} 

exports.checkStickyHeight = checkStickyHeight