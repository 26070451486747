import { isSet } from "../functions/isset";

/**
 * Description: Location Google Maps Setup
 * 
 * @class LocationMap
 * @typedef {LocationMap}
 */

class LocationMap {
    constructor (domNode, MapLib, MarkerLib, InfoWindowLib) {
        this.map;

        this.domNode = domNode;
        this.dataUrl = domNode.dataset.url;
        this.mapElm = this.domNode.querySelector("#map");
        this.zoomOutControlElm = this.domNode.querySelector(".map-controls__zoom-out");
        this.zoomInControlElm = this.domNode.querySelector(".map-controls__zoom-in");
        this.resetControlElm = this.domNode.querySelector(".map-controls__reset");
        this.pin = "./images/svg/pin.svg";

        //Google Map Libraries
        this.MapLib = MapLib;
        this.MarkerLib = MarkerLib;
        this.InfoWindowLib = InfoWindowLib;

        this.mapMarkers = [];
        this.mapWindows = [];
        this.locations = [];

        if(isSet(domNode.dataset.zoom) && domNode.dataset.zoom !== ""){
            this.zoom = Number(domNode.dataset.zoom);
        } else{
            this.zoom = 9;
        }

        if(isSet(domNode.dataset.lng) && domNode.dataset.lng !== ""){
            this.lng = Number(domNode.dataset.lng);
        } else{
            this.lng = -3.405124;
        }

        if(isSet(domNode.dataset.lat) && domNode.dataset.lat !== ""){
            this.lat = Number(domNode.dataset.lat);
        } else{
            this.lat = 56.216194;
        }

        if(isSet(domNode.dataset.defaultSlug) && domNode.dataset.defaultSlug !== ""){
            this.defaultSlug = domNode.dataset.defaultSlug;
        }

        this.map = new this.MapLib(this.mapElm, {
            center: {
                lat: this.lat,
                lng: this.lng
            },
            zoom: this.zoom,
            mapId: 'fe231c3d7f1f9b41',
            streetViewControl: false,
            clickableIcons: false,
            mapTypeControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
            scrollwheel: false
        });

        this.zoomOutControlElm.addEventListener('click', this.handleMapZoomOut.bind(this), false);
        this.zoomInControlElm.addEventListener('click', this.handleMapZoomIn.bind(this), false);
        this.resetControlElm.addEventListener('click', this.handleMapReset.bind(this), false);

        this.loadLocations(this.dataUrl);
    }


    async loadLocations(url){
        const jsonres = await fetch(url);
        const jsonList = await jsonres.json();
        this.jsonList = jsonList;
        this.buildLocations(jsonList);
    }

    buildLocations(locations){
        locations.map((location) => {
            const lat = parseFloat(location.lat);
            const lng = parseFloat(location.lng);

            const locationObject = {
                "slug": location.slug,
                "position": new google.maps.LatLng(lat, lng),
                "content": `
                    <div class="gmap--info-window">
                        <h4>
                            <a href="${location.permalink}">
                                ${location.title}
                            </a>
                        </h4>
                        <p>${location.address}</p>
                    </div>
                `
            };

            this.locations.push(locationObject);
        });

        this.buildMarkers();
    }

    buildMarkers(){
        const map = this.map;
        const locations = this.locations;
        const pin = this.pin;

        for (let i = 0; i < locations.length; i++) {
            const location = locations[i];

            const marker = new this.MarkerLib(
                {
                    position: location.position,
                    icon: pin,
                    map: map,
                }
            );

            this.mapMarkers.push(marker);

            const window = new this.InfoWindowLib({
                content: location.content
            });

            if(isSet(this.defaultSlug) && this.defaultSlug == location.slug){
                window.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });

                google.maps.event.addListener(map, 'click', function() {
                    window.close();
                    marker.open = false;
                });
            }

            this.mapWindows.push(window);

            const markerHandler = (e) => this.handleMapMarkerClick(marker, window, e);
            marker.addListener("click", markerHandler);
        }
    }

    handleMapMarkerClick(marker, window, e){
        const map = this.map;
        const windows = this.mapWindows;

        for (let i = 0; i < windows.length; i++) {
            const window = windows[i];
            window.close();
        }

        map.panTo(
            marker.position
        );

        window.open({
            anchor: marker,
            map,
            shouldFocus: false,
        });
    }

    handleMapZoomIn(){
        const map = this.map;
        map.setZoom(map.getZoom() + 1);
    }

    handleMapZoomOut(){
        const map = this.map;
        map.setZoom(map.getZoom() - 1);
    }

    handleMapReset(){
        const position = new google.maps.LatLng(this.lat, this.lng);
        this.map.setZoom(this.zoom);
        this.map.panTo(position);
    }
}

exports.LocationMap = LocationMap