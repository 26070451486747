import { DonateCore } from "./DonateCore";
import { isSet } from "../functions/isset";

class DonateWidget extends DonateCore {
    constructor (domNode) {
        super(domNode);

        this.continueButton = this.domNode.querySelector('.donate-widget__action .wp-block-button__link');
        

        if(isSet(this.continueButton)){
            this.baseURL = this.continueButton.href;

            this.continueButton.addEventListener(
                'click',
                this.onContinueButtonClick.bind(this)
            );
        }
    }

    /**
     * Escapes a string
     * @param  {String}  string  The string that is to be escaped
     */
    escape(string) {
        if(!isSet(string)) return;
        return string
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
    }

    setQueryString(){
        const donationFrequency = this.domNode.querySelector('.donate-widget__fequency input[type="radio"]:checked');

        let url = new URL(this.baseURL);

        if(this.donationAmount !== "" && isSet(this.donationAmount)){
            url.searchParams.append("donationAmount", this.escape(this.donationAmount));
        }
        
        if(isSet(donationFrequency)){
            url.searchParams.append("donationFrequency", this.escape(donationFrequency.value));
        }

        this.continueButton.href = url + "#make-a-donation";
    }

    onContinueButtonClick(e){
        e.stopPropagation();
        e.preventDefault();

        this.setQueryString();
        window.location.href = this.continueButton.href;
    }
}

exports.DonateWidget = DonateWidget