import { fbButton } from 'vanilla-sharing';
import { tw } from 'vanilla-sharing';
import { email } from 'vanilla-sharing';
import { linkedin } from 'vanilla-sharing';
import { whatsapp } from 'vanilla-sharing';
import { isSet } from "./isset";

function shareButtons(domNode){
    if(!isSet(domNode)) return;

    const URL = window.location.href;
    const TITLE = document.title;
    const DESCRIPTION = document.querySelector('meta[name="description"]').content;

    const shareFacebookButton = domNode.querySelector('.share-button--facebook');
    const shareTwitterButton = domNode.querySelector('.share-button--twitter');
    const shareEmailButton = domNode.querySelector('.share-button--email');
    const shareLinkedinButton = domNode.querySelector('.share-button--linkedin');
    const shareWhatsappButton = domNode.querySelector('.share-button--whatsapp');

    if(isSet(shareFacebookButton)){
        shareFacebookButton.addEventListener('click', function() {
            fbButton({
                url: URL,
            });
        });
    }

    if(isSet(shareTwitterButton)){
        shareTwitterButton.addEventListener('click', function() {
            tw({
                url: URL,
                title: TITLE,
                hashtags: ['TheYardScotland', 'TheYard'],
            });
        });
    }

    if(isSet(shareEmailButton)){
        shareEmailButton.addEventListener('click', function() {
            email({
                url: URL,
                title: TITLE,
                subject: TITLE,
                description: DESCRIPTION
            });
        });
    }

    if(isSet(shareLinkedinButton)){
        shareLinkedinButton.addEventListener('click', function() {
            linkedin({
                url: URL,
                title: TITLE,
                description: DESCRIPTION
            });
        });
    }

    if(isSet(shareWhatsappButton)){
        shareWhatsappButton.addEventListener('click', function() {
            whatsapp({
                url: URL,
                title: TITLE
            });
        });
    }
}

exports.shareButtons = shareButtons