function loadScript(url, successFunction) {
    let script = document.createElement('script');
    script.src = url;
    let head = document.getElementsByTagName('head')[0],
    done = false;
    head.appendChild(script);

    // Attach handlers for all browsers
    script.onload = script.onreadystatechange = function () {
        if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) {
            done = true;

            if (successFunction) { 
                successFunction(); 
            }
            script.onload = script.onreadystatechange = null;
            head.removeChild(script);
        }
    };
}

exports.loadScript = loadScript