class StickyHeader {
    constructor (domNode) {
        this.stickyHeader = domNode;
        this.prevScroll = window.scrollY || document.documentElement.scrollTop;
        this.currentScroll;
        this.headerHeight;
        this.direction = 0;
        this.prevDirection = 0;
        this.stickyOffset = domNode.offsetTop;

        window.addEventListener(
            'scroll', 
            this.checkOffset.bind(this)
        );

        window.addEventListener(
            'scroll', 
            this.checkScroll.bind(this)
        );

        document.addEventListener(
            'DOMContentLoaded', 
            this.setMargin.bind(this)
        );

        window.addEventListener(
            'resize', 
            this.setMargin.bind(this)
        );
    }

    checkOffset() {
        if (window.scrollY > this.stickyOffset) {
            this.stickyHeader.classList.add("sticky");
        } else {
            this.stickyHeader.classList.remove("sticky");
        }
    }

    toggleHeader(direction, currentScroll) {
        if (direction === 2 && currentScroll > this.headerHeight) { 
            this.stickyHeader.classList.add('hide');
            this.prevDirection = direction;
        }
        else if (direction === 1) {
            this.stickyHeader.classList.remove('hide');
            this.prevDirection = direction;
        }
    }

    checkScroll() {
        this.currentScroll = window.scrollY || document.documentElement.scrollTop;
        if (this.currentScroll > this.prevScroll ) {
            this.direction = 2;
        } else if (this.currentScroll < this.prevScroll) {
            this.direction = 1;
        }

        if (this.direction !== this.prevDirection) {
            this.toggleHeader(this.direction, this.currentScroll);
        }

        this.prevScroll = this.currentScroll;
    }

    setMargin() {
        document.documentElement.style.marginTop = this.stickyHeader.offsetHeight + 'px';
        document.documentElement.style.setProperty('--masthead-height', this.stickyHeader.offsetHeight + 'px');
        this.headerHeight = this.stickyHeader.offsetHeight;
    }
}

exports.StickyHeader = StickyHeader