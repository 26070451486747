import { isSet } from "./isset";

function copyField(domNode){
    if(!isSet(domNode)) return;

    const input = domNode.querySelector('input');
    const button = domNode.querySelector('button');

    if(!isSet(input) || !isSet(button)) return;

    input.value = window.location.href;

    input.addEventListener('focus', function() {
        input.select();
    });

    button.addEventListener('click', function() {
        const copyContent = async () => {
            try {
                await navigator.clipboard.writeText(input.value);
                button.querySelector('span').textContent = 'Copied!';
                button.querySelector('i').classList.remove('yard-icon--copy');
                button.querySelector('i').classList.add('yard-icon--check');

                setTimeout(() => {
                    button.querySelector('span').textContent = 'Copy';
                    button.querySelector('i').classList.remove('yard-icon--check');
                    button.querySelector('i').classList.add('yard-icon--copy');
                }, 2000);
                  
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        }

        copyContent();
    });
}

exports.copyField = copyField