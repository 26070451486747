import { initEmblaCarousels } from "./functions/emblaCarouselInit";
import { isJavascriptEnabled } from "./functions/enabled";
import { isSet } from "./functions/isset";
import { setFocusableElements } from "./functions/setFocusableElements";
import { clipPathRandomizer } from "./functions/clipPathRandomizer";
import { checkStickyHeight } from "./functions/checkStickyHeight";
import { shareButtons } from "./functions/shareButtons";
import { copyField } from "./functions/copyField";
import { loadScript } from "./functions/loadScript";
import { initDonorfyStripe } from "./functions/donorfyStripe";
import { initDonorfyGoCardless } from "./functions/donorfyGoCardless";
import { TabList} from "./classes/Tabs";
import { CounterField } from "./classes/CounterField";
import { DonateCore } from "./classes/DonateCore";
import { DonateForm } from "./classes/DonateForm";
import { DonateWidget } from "./classes/DonateWidget";
import { StickyHeader } from "./classes/StickyHeader";
import { InternalNav } from "./classes/InternalNav";
import { LocationMap } from "./classes/LocationMap";
import { Video } from "./classes/Video";
import { Dropdown } from "./classes/Dropdown";
import { Layer } from "./classes/Layer";
import { MobileMenu } from "./classes/MobileMenu";


isJavascriptEnabled();
setFocusableElements();

/**
 * Initialise any and all carousels
 */
initEmblaCarousels();


/**
 * Initialise Sticky Header for site header
 */
const masthead = document.getElementById('masthead');
if(isSet(masthead)){
    new StickyHeader(masthead);
}


/**
 * Initialise Site Header Dropdowns
 */
const subMenuDropdowns = document.querySelectorAll("#site-nav .has-sub-menu--js");
let desktopSubMenus = [];
let mobileSubMenus = [];

window.addEventListener(
    'resize', 
    function(){
        if (window.innerWidth < 900){
            desktopSubMenus.forEach( (desktopSubMenu) => {
                desktopSubMenu.closeDropdown();
            });
        } else {
            mobileSubMenus.forEach( (mobileSubMenu) => {
                mobileSubMenu.closeDropdown();
            });
        }
    }
);

subMenuDropdowns.forEach( (subMenuElement) => {
    if (!window.innerWidth < 900){
        const desktopSubMenu = new Dropdown(
            subMenuElement,
            {
                tabNavigation: true
            }
        );
    
        desktopSubMenus.push(desktopSubMenu);
    }
});

window.addEventListener('mobileMenuInitialised', () => {
    desktopSubMenus.forEach( (desktopSubMenu) => {
        desktopSubMenu.destroy();
    });

    desktopSubMenus = [];

    subMenuDropdowns.forEach( (subMenuElement) => {
        const mobileSubMenu = new Dropdown(
            subMenuElement,
            {
                tabNavigation: true,
                closeOnBackgroundClick: false
            }
        );

        mobileSubMenus.push(mobileSubMenu);
    });
});

window.addEventListener('mobileMenuDestroyed', () => {
    mobileSubMenus.forEach( (mobileSubMenu) => {
        mobileSubMenu.destroy();
    });

    mobileSubMenus = [];

    subMenuDropdowns.forEach( (subMenuElement) => {
        const desktopSubMenu = new Dropdown(
            subMenuElement,
            {
                tabNavigation: true
            }
        );

        desktopSubMenus.push(desktopSubMenu);
    });
});

const siteActionDropdowns = document.querySelectorAll(".site-nav--actions .has-sub-menu--js");
siteActionDropdowns.forEach( function (siteActionElement) {
    new Dropdown(
        siteActionElement,
		{
            tabNavigation: true
        }
    );
});


/**
 * Initialise Mobile Menu
 */
const mobileMenu = document.querySelector('.site-nav--js');
if(isSet(mobileMenu)){
    new MobileMenu(mobileMenu);
}


/**
 * Height checking for other sticky elements
 */
const stickyElms = document.querySelectorAll(".is-sticky");
stickyElms.forEach( function (stickyElm) {
    checkStickyHeight(stickyElm);
});


/**
 * Initialise Internal Navs
 */
const internalNavElement = document.querySelector(".internal-nav--js");
if(isSet(internalNavElement)){
    new InternalNav(internalNavElement);
}


/**
 * Initialise Share Modals
 */
const shareModal = document.querySelector(".share--js");
if(isSet(shareModal)){
    new Layer(
        shareModal,
		{}
    );
}


/**
 * Initialise share buttons
 */
const shareButtonsContainer = document.querySelector('.share-buttons--js');
if(isSet(shareButtonsContainer)){
    shareButtons(shareButtonsContainer);
}


/**
 * Initialise Copy Fields Dropdowns
 */
const copyFields = document.querySelectorAll(".copy-link--js");
copyFields.forEach( function (copyFieldElm) {
    copyField(copyFieldElm);
});


/**
 * Initialise Counter Fields Dropdowns
 */
const counterFields = document.querySelectorAll(".field--counter--js");
counterFields.forEach( function (counterField) {
    new CounterField(
        counterField,
		{}
    );
});


/**
 * Initialise TabLists
 */
const tabbedAccordions = document.querySelectorAll(".tabbed-accordion--js");
tabbedAccordions.forEach( function (tabbedAccordionElement) {
    new TabList(
        tabbedAccordionElement,
		{
			orientation: 'vertical',
            convertToAccordion: true,
			accordionBreakpoint: '767'
		}
    );
});

const donationTabs = document.querySelectorAll(".donation-tabs--js");
donationTabs.forEach( function (donationTabElement) {
    new TabList(
        donationTabElement,
		{}
    );
});


/**
 * Initialise Donation Sections
 */
const donateSections = document.querySelectorAll(".donation--js");
donateSections.forEach( function (donateSection) {
    new DonateCore(
        donateSection,
		{}
    );
});

const donateForms = document.querySelectorAll(".donorfy-panel form .donation--js");
donateForms.forEach( function (donateForm) {
    new DonateForm(
        donateForm,
		{}
    );
});

const donateWidgets = document.querySelectorAll(".section--donate-widget .donation--js");
donateWidgets.forEach( function (donateWidget) {
    new DonateWidget(
        donateWidget,
		{}
    );
});


/**
 * Set random clip paths for various elements
 */
const mediaTextFigures = document.querySelectorAll(".section--media-text--js figure");
mediaTextFigures.forEach( function (figure) {
    clipPathRandomizer(figure, 'box');
});

const floatingImg = document.querySelectorAll(".block--float-para--js img");
floatingImg.forEach( function (img) {
    clipPathRandomizer(img, 'box');
});


/**
 * Load Google Maps Api and initialise Map
 * Google makes loading multiple maps weirdly difficult, so just init the first map we come across
 */
(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
    key: "AIzaSyAkwKJ_j13ODgogyUIcG69jOYpE9tWAEgc",
    v: "weekly",
});

const mapContainer = document.querySelector('.locations--js');
if(isSet(mapContainer)){
    async function initMap() {
        const {Map, InfoWindow} = await google.maps.importLibrary("maps");
        const {Marker} = await google.maps.importLibrary("marker");
    
        new LocationMap(mapContainer, Map, Marker, InfoWindow);
    }
    
    initMap();
}


/**
 * Initialise Video Modules
 */
const youtubeApiLoadedEvent = new Event('youtubeApiLoaded');

function onYouTubeIframeAPIReady() { //Gets called automatically by Youtube API
    window.dispatchEvent(youtubeApiLoadedEvent);
}

const videos = document.querySelectorAll('.video-module--js');
videos.forEach(function (video) {
    new Video(video);
});


const stripeForm = document.querySelector('#CreditCardForm');
if(isSet(stripeForm)){
    initDonorfyStripe();
}

const goCardlessForm = document.querySelector('#DirectDebitForm');
if(isSet(goCardlessForm)){
    initDonorfyGoCardless();
}
