/**
 * Adds body class to check if JS is enabled
 * @returns {Boolean}
 */
function setFocusableElements(){
    const focusableElements = [
        'a[href]:not([disabled]):not([hidden]):not([aria-hidden]):not([tabindex^="-"])',
        'area[href]:not([disabled]):not([hidden]):not([aria-hidden]):not([tabindex^="-"])',
        'input:not([disabled]):not([type="hidden"]):not([aria-hidden]):not([tabindex^="-"])',
        'select:not([disabled]):not([aria-hidden]):not([tabindex^="-"])',
        'textarea:not([disabled]):not([aria-hidden]):not([tabindex^="-"])',
        'button:not([disabled]):not([aria-hidden]):not([tabindex^="-"])',
        'iframe:not([disabled]):not([hidden]):not([aria-hidden]):not([tabindex^="-"])',
        'object:not([disabled]):not([hidden]):not([aria-hidden]):not([tabindex^="-"])',
        'embed:not([disabled]):not([hidden]):not([aria-hidden]):not([tabindex^="-"])',
        '[contenteditable]:not([disabled]):not([hidden]):not([aria-hidden]):not([tabindex^="-"])',
        '[tabindex]:not([tabindex^="-"])'
    ];
    if (typeof window !== 'undefined') {
        window.focusableElements = focusableElements;
    }
}

exports.setFocusableElements = setFocusableElements