import { isSet } from "../functions/isset";

/**
 * Description: Initialise video player
 * 
 * @class Video
 * @typedef {Video}
 */


class Video {
    constructor(domNode) {
        this.domNode = domNode;

        window.addEventListener(
            'load',
            this.init.bind(this),
            true
        );

        this.init();
    }

    init(){
        this.playerContainer = this.domNode.querySelector('.video-module__media figure');
        if (!isSet(this.playerContainer)){
            console.warn("Element `.video-module__media figure` does not exist");
            return;
        };

        this.posterImage = this.domNode.querySelector('.video-module__poster img');
        if (!isSet(this.posterImage)){
            console.warn("Element `.video-module__poster img` does not exist");
            return;
        };

        this.actionButton = this.domNode.querySelector('.video-module__poster button.video-module__play');
        if (!isSet(this.actionButton)){
            console.warn("Element `.video-module__poster button.video-module__play` does not exist");
            return;
        };

        if(this.domNode.dataset.youtubeId && this.domNode.dataset.youtubeId !== ""){
            this.initYoutubeEmbed();
        }if(this.domNode.dataset.vimeoUrl && this.domNode.dataset.vimeoUrl !== ""){
            this.initVimeoEmbed();
        }else{
            this.initInlineVideo();
        }

        this.actionButton.addEventListener(
            'click',
            this.handlePlayButtonClick.bind(this),
            true
        );
    }

    initYoutubeEmbed(){
        this.playerType = 'youtube';

        window.addEventListener(
            "youtubeApiLoaded",
            this.handleYoutubePlayer.bind(this),
            true,
        );

        const scriptNode = document.querySelector('.youtube-api-script');
        if (!isSet(scriptNode)){
            this.loadApiScript();
        }

        this.youtubeId = this.domNode.dataset.youtubeId;
    }

    initVimeoEmbed(){
        this.playerType = 'vimeo';
        this.vimeoUrl = this.domNode.dataset.vimeoUrl;

        this.handleVimeoPlayer();
    }

    initInlineVideo(){
        this.playerType = "inline";

        this.videoNode = this.domNode.querySelector('.video-module__media video');
        if (!isSet(this.videoNode)){
            console.warn("Element `.video-module__media video` does not exist");
        }
    }

    handlePlayButtonClick(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        switch (this.playerType) {
            case 'youtube':
                this.youtubePlayer.playVideo();
                this.removeVideoPoster();
                break;
            case 'vimeo':
                this.removeVideoPoster();
                break;
            case 'inline':
                this.videoNode.play();
                this.removeVideoPoster();
            default:
                console.warn(`this.playerType did not match a valid value`);
        }
    }

    removeVideoPoster(){
        const videoModulePoster = this.domNode.querySelector('.video-module__poster');
        videoModulePoster.remove();
    }

    handleYoutubePlayer(){
        this.youtubePlayer = new YT.Player(this.playerContainer, {
            height: '765',
            width: '1360',
            host: 'https://www.youtube-nocookie.com',
            videoId: this.youtubeId,
            playerVars: {
              'playsinline': 1,
              'origin': window.location.host
            }
        });
    }

    async handleVimeoPlayer(){
        const vimeoRequestUrl = "https://vimeo.com/api/oembed.json?url=" + this.vimeoUrl;
        try {
            const vimeoResponse = await fetch(vimeoRequestUrl);
            const vimeoJSON = await vimeoResponse.json();
            const vimeoHTML = vimeoJSON.html;

            this.playerContainer.insertAdjacentHTML('beforeend' , vimeoHTML);
        } catch (err) {
            console.error(err);
        }
    }

    loadYoutubeApiScript(){
        const tag = document.createElement('script');
        tag.classList.add('youtube-api-script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
}

exports.Video = Video