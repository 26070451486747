import { loadScript } from "./loadScript";

function initDonorfyGoCardless(){
    if (typeof jQuery == 'undefined') {
        loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js', function () {
            jQuery(document).ready(function () {
                Donorfy__GoCardless__load();
            });
        });
        
    } else {
        jQuery(document).ready(function () {
            Donorfy__GoCardless__load();
        });
    }
    
    function Donorfy__GoCardless__GetBaseServiceUrl() {
        return "https://api.donorfy.com/api/gocardless2/";
    }
    
    function Donorfy__GoCardless__load() {
        if (typeof jQuery.validator === 'undefined') {
            loadScript('https://ajax.aspnetcdn.com/ajax/jquery.validate/1.13.1/jquery.validate.min.js');
        }
        jQuery('#DirectDebitForm').submit(function (event) {
    
            if (Donorfy__GoCardless__ValidateForm()) {
                // Disable the submit button to prevent repeated clicks
                jQuery('#Donorfy__GoCardless__submitButton').attr('disabled', 'disabled');
                jQuery('#Donorfy__GoCardless__PleaseWait').show();
                Donorfy__GoCardless__PostPayment();
            }
            // Prevent the form from submitting with the default action
            return false;
        });
    
        jQuery(document).ajaxSend(function () {
            jQuery('#Donorfy__GoCardless__spinner').show();
        });
    
        jQuery(document).ajaxStop(function () {
            jQuery('#Donorfy__GoCardless__spinner').hide();
        });
    
        jQuery(document).ajaxError(function () {
            jQuery('#Donorfy__GoCardless__spinner').hide();
        });
    
        jQuery('input.Donorfy__GoCardless__numberOnly[type=text]').on('keypress', function (e) {
            //if the letter is not digit then display error and don't type anything
            if (e.which != 8 && e.which != 44 && e.which != 45 && e.which != 46 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                return false;
            }
        });
    
        jQuery('input#Donorfy__GoCardless__Amount').blur(function () {
    
            if (this.value) {
                var amt = parseFloat(this.value);
                jQuery(this).val(amt.toFixed(2));
            }
        });
    }
    
    function Donorfy__GoCardless__ValidateForm() {
    
        jQuery('#DirectDebitForm').validate({
    
            groups: {
                sortcodeGroup: 'Donorfy__GoCardless__SortCode1 Donorfy__GoCardless__SortCode2 Donorfy__GoCardless__SortCode3'
            },
            errorPlacement: function (error, element) {
                if (
                    element.attr('name') == 'Donorfy__GoCardless__SortCode1' 
                    || 
                    element.attr('name') == 'Donorfy__GoCardless__SortCode2' 
                    || 
                    element.attr('name') == 'Donorfy__GoCardless__SortCode3'
                )
                    error.insertAfter('#Donorfy__GoCardless__SortCode3');
                else
                    error.insertAfter(element);
            },
    
        }).settings.ignore = ':disabled,:hidden';
    
    
        return jQuery('#DirectDebitForm').valid();
    }
    
    function Donorfy__GoCardless__PostPayment() {
    
        jQuery.ajax({
            dataType: 'json',
            url: Donorfy__GoCardless__GetBaseServiceUrl() + 'directdebitpayment',
            data: Donorfy__GoCardless__GetPaymentPostData(),
            async: false,
            method: 'POST',
            type: 'POST'
        }).done(function (data) {
    
            if (data.OK) {
                Donorfy__GoCardless__RedirectToGoCardless(data.RedirectUrl);
            } else {
                jQuery('#Donorfy__GoCardless__submitButton').removeAttr('disabled');
                jQuery('#Donorfy__GoCardless__PleaseWait').hide();
                Donorfy__GoCardless__DisplayErrors(data.Errors);
            }
    
        }).fail(function (jqXHR, textStatus, errorThrown) {
    
            jQuery('#Donorfy__GoCardless__submitButton').removeAttr('disabled');
            jQuery('#Donorfy__GoCardless__PleaseWait').hide();
            Donorfy__GoCardless__DisplayErrors(Donorfy__GoCardless__GetErrorArray(jqXHR))
        });
    
    }
    
    function Donorfy__GoCardless__GetPaymentPostData() {
    
        return {
            title: jQuery('#Donorfy__GoCardless__Title').val(),
            firstName: jQuery('#Donorfy__GoCardless__FirstName').val(),
            lastName: jQuery('#Donorfy__GoCardless__LastName').val(),
            email: jQuery('#Donorfy__GoCardless__Email').val(),
            phone: jQuery('#Donorfy__GoCardless__Phone').val(),
            address1: jQuery('#Donorfy__GoCardless__Address1').val(),
            address2: jQuery('#Donorfy__GoCardless__Address2').val(),
            town: jQuery('#Donorfy__GoCardless__Town').val(),
            county: jQuery('#Donorfy__GoCardless__County').val(),
            postCode: jQuery('#Donorfy__GoCardless__Postcode').val(),
            country: jQuery('#Donorfy__GoCardless__Country').length > 0 ? jQuery('#Donorfy__GoCardless__Country').val() : '',
            giftAid: jQuery('#Donorfy__GoCardless__GiftAid').is(':checked'),
            keepInTouch: Donorfy__GoCardless__GetKeepInTouchValue(),
            doNotKeepInTouch: Donorfy__GoCardless__GetDoNotKeepInTouchValue(),
            optInShown: Donorfy__GoCardless__GetOptInShownValue(),
            legitInterestShown: Donorfy__GoCardless__GetLegitInterestShownValue(),
            amount: jQuery('#Donorfy__GoCardless__Amount').val(),
            accountNumber: jQuery('#Donorfy__GoCardless__AccountNumber').val(),
            sortCode: Donorfy__GoCardless__GetSortCode(),
            tenantCode: jQuery('#Donorfy__GoCardless__TenantCode').val(),
            widgetId: jQuery('#Donorfy__GoCardless__WidgetId').val(),
            paymentSchedule: jQuery('input:radio[name=PaymentSchedule]:checked').val(),
            comment: jQuery('#Donorfy__GoCardless__Comment').val(),
            quantity: jQuery('#Donorfy__GoCardless__Quantity').length > 0 ? jQuery('#Donorfy__GoCardless__Quantity').val() : '1',
            additionalTitle: jQuery('#Donorfy__GoCardless__AdditionalTitle').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalTitle').val() : '',
            additionalFirstName: jQuery('#Donorfy__GoCardless__AdditionalFirstName').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalFirstName').val() : '',
            additionalLastName: jQuery('#Donorfy__GoCardless__AdditionalLastName').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalLastName').val() : '',
            additionalEmail: jQuery('#Donorfy__GoCardless__AdditionalEmail').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalEmail').val() : '',
            additionalPhone: jQuery('#Donorfy__GoCardless__AdditionalPhone').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalPhone').val() : '',
            additionalAddress1: jQuery('#Donorfy__GoCardless__AdditionalAddress1').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalAddress1').val() : '',
            additionalAddress2: jQuery('#Donorfy__GoCardless__AdditionalAddress2').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalAddress2').val() : '',
            additionalTown: jQuery('#Donorfy__GoCardless__AdditionalTown').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalTown').val() : '',
            additionalCounty: jQuery('#Donorfy__GoCardless__AdditionalCounty').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalCounty').val() : '',
            additionalPostcode: jQuery('#Donorfy__GoCardless__AdditionalPostcode').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalPostcode').val() : '',
            additionalCountry: jQuery('#Donorfy__GoCardless__AdditionalCountry').length > 0 ? jQuery('#Donorfy__GoCardless__AdditionalCountry').val() : '',
            activeTags: jQuery('#Donorfy__GoCardless__ActiveTags').length > 0 ? jQuery('#Donorfy__GoCardless__ActiveTags').val() : '',
            blockedTags: jQuery('#Donorfy__GoCardless__BlockedTags').length > 0 ? jQuery('#Donorfy__GoCardless__BlockedTags').val() : ''
        };
    }
    
    function Donorfy__GoCardless__GetOptInShownValue() {
    
        var keepInTouchValue = 0;
    
        jQuery('input.Donorfy__GoCardless__KeepInTouch[type=checkbox]').each(function () {
            keepInTouchValue += parseInt(jQuery(this).val());
        });
    
        return keepInTouchValue;
    }
    
    function Donorfy__GoCardless__GetLegitInterestShownValue() {
    
        var doNotKeepInTouchValue = 0;
    
        jQuery('input.Donorfy__GoCardless__DoNotKeepInTouch[type=checkbox]').each(function () {
            doNotKeepInTouchValue += parseInt(jQuery(this).val());
        });
    
        return doNotKeepInTouchValue;
    }
    
    function Donorfy__GoCardless__GetKeepInTouchValue() {
    
        var keepInTouchValue = 0;
    
        jQuery('input.Donorfy__GoCardless__KeepInTouch[type=checkbox]:checked').each(function () {
            keepInTouchValue += parseInt(jQuery(this).val());
        });
    
        return keepInTouchValue;
    }
    
    function Donorfy__GoCardless__GetDoNotKeepInTouchValue() {
    
        var doNotKeepInTouchValue = 0;
    
        jQuery('input.Donorfy__GoCardless__DoNotKeepInTouch[type=checkbox]:checked').each(function () {
            doNotKeepInTouchValue += parseInt(jQuery(this).val());
        });
    
        return doNotKeepInTouchValue;
    }
    
    function Donorfy__GoCardless__GetSortCode() {
    
        return jQuery('#Donorfy__GoCardless__SortCode1').val() + jQuery('#Donorfy__GoCardless__SortCode2').val() + jQuery('#Donorfy__GoCardless__SortCode3').val();
    
    }
    
    function Donorfy__GoCardless__GetErrorArray(jqXHR) {
    
        var errors = [];
    
        var response = JSON.parse(jqXHR.responseText);
    
        if (response.ModelState) {
            for (var key in response.ModelState) {
                errors.push(response.ModelState[key]);
            }
        }
        else if (response.Message) {
            errors.push(response.Message);
        }
        else {
            errors.push('An unexpected error occurred.');
        }
    
        return errors;
    }
    
    function Donorfy__GoCardless__DisplayErrors(errors) {
    
        jQuery('#Donorfy__GoCardless__ErrorContainer').show();
    
        var errorMessage = '';
    
        jQuery.each(errors, function (index, value) {
    
            errorMessage += value + '<br/>';
        });
    
        jQuery('#Donorfy__GoCardless__Errors').html(errorMessage);
    }
    
    function Donorfy__GoCardless__RedirectToGoCardless(redirectUrl) {
        //redirect to GoCardless payment pages
        if (redirectUrl) {
            window.location = redirectUrl;
        } else {
            var errors = [];
            errors.push('Unable to redirect to GoCardless');
            Donorfy__GoCardless__DisplayErrors(errors);
        }
    }
}

exports.initDonorfyGoCardless = initDonorfyGoCardless