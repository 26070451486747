import { Dropdown } from "./Dropdown";
import { isSet } from "../functions/isset";

/**
 * Description: Initialise internal nav heights etc
 * 
 * @class InternalNav
 * @typedef {InternalNav}
 */

class InternalNav {
    constructor (domNode) {
        this.domNode = domNode;
        this.documentElm = document.documentElement;

        document.addEventListener(
            'DOMContentLoaded', 
            this.setHeight.bind(this)
        );

        window.addEventListener(
            'resize', 
            this.setHeight.bind(this)
        );

        this.init();
    }

    init(){
        if (window.innerWidth < 767) {
            if(isSet(this.dropdown)){
                this.dropdown.init();
            }else{
                this.dropdown = new Dropdown(
                    this.domNode,
                    {
                        closeOnSelect: true
                    }
                );
            }
		}else{
            if(isSet(this.dropdown)){
                this.dropdown.destroy();
            }
        }
    }

    setHeight() {
        let internalNavHeight = this.domNode.offsetHeight + 18;
        this.documentElm.style.setProperty('--internal-nav-height', internalNavHeight + 'px');
    }
}

exports.InternalNav = InternalNav