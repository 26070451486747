/**
 * Description: Initialise donate widgets
 * 
 * @class DonateWidget
 * @typedef {DonateWidget}
 */

class DonateCore {
    constructor (domNode) {
        this.domNode = domNode;
        this.customAmountInput = this.domNode.querySelector('.donate-widget__amount .donate-widget__text [type="number"]');
        this.customAmountField = this.customAmountInput.closest('.donate-widget__field');
        this.presetAmountInputs = this.domNode.querySelectorAll('.donate-widget__amount .donate-widget__radio [type="radio"]');

        this.customAmountInput.addEventListener(
            'keydown',
            this.handleCustomAmountKeydown.bind(this)
        );

        this.customAmountInput.addEventListener(
            'change',
            this.handleCustomAmountChange.bind(this)
        );

        this.customAmountInput.addEventListener(
            'focus',
            this.handleCustomAmountFocus.bind(this)
        );

        this.customAmountInput.addEventListener(
            'blur',
            this.handleCustomAmountBlur.bind(this)
        );

        this.customAmountInput.addEventListener(
            'keyup',
            this.handleCustomAmountKeyup.bind(this)
        );

        this.customAmountField.addEventListener(
            'click',
            this.handleCustomFieldClick.bind(this)
        );

        this.presetAmountInputs.forEach( (input) => {
            input.addEventListener(
                'click',
                this.handlePresetInputClick.bind(this)
            );
        });

        this.amountChangeEventDetail = { detail: { donationAmount: this.donationAmount } };
        this.donationAmountChange = new CustomEvent('donationAmountChange', this.amountChangeEventDetail);
    }

    handlePresetInputClick(e){
        this.customAmountField.classList.remove('is-selected');

        this.donationAmount = e.target.value;
        this.domNode.dispatchEvent(this.donationAmountChange);
    }

    handleCustomAmountChange(e){
        this.donationAmount = e.target.value;
        this.domNode.dispatchEvent(this.donationAmountChange);
    }

    handleCustomAmountFocus(){
        this.presetAmountInputs.forEach( (input) => {
            if(input.checked){
                this.previousSelectedPreset = input;
            }

            if(this.customAmountInput.value){
                input.checked = false;
            }
        });

        if(this.customAmountInput.value){
            this.customAmountField.classList.add('is-selected');
        }else{
            this.customAmountField.classList.remove('is-selected');
        }
    }

    handleCustomFieldClick(){
        this.customAmountInput.focus();

        if(this.customAmountInput.value){
            this.donationAmount = this.customAmountInput.value;
            this.domNode.dispatchEvent(this.donationAmountChange);
        }
    }

    handleCustomAmountKeyup(){
        this.presetAmountInputs.forEach( (input) => {
            if(input.checked){
                this.previousSelectedPreset = input;
            }

            if(this.customAmountInput.value){
                input.checked = false;
            }
        });

        if(this.customAmountInput.value){
            this.customAmountField.classList.add('is-selected');

            this.donationAmount = this.customAmountInput.value;
            this.domNode.dispatchEvent(this.donationAmountChange);
        }else{
            this.customAmountField.classList.remove('is-selected');

            if(this.previousSelectedPreset){
                this.previousSelectedPreset.checked = true;

                this.donationAmount = this.previousSelectedPreset.value;
                this.domNode.dispatchEvent(this.donationAmountChange);
            }
        }
    }

    handleCustomAmountKeydown(e){
        const key = e.key;
        const keyCode = e.keyCode;
        const keyString = String.fromCharCode(keyCode);
        const regex = /[0-9]|\./;

        let flag = false;

        switch (key) {
            case 'Tab':
            case 'Up':
            case 'ArrowUp':
            case 'Down':
            case 'ArrowDown':
            case 'Right':
            case 'ArrowRight':
            case 'Left':
            case 'ArrowLeft':
            case 'Backspace':
            case '.':
                flag = false;
                break;
            default:
                flag = true;
                break;
        }

        if (regex.test(keyString)) {
            flag = false;
        }

        if (e.ctrlKey || e.altKey || e.metaKey || e.shiftKey){
            flag = true;
        }

        if (flag) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    handleCustomAmountBlur(e){
        if(!e.target.value) return;

        const amount = parseFloat(
            parseFloat(e.target.value).toFixed(2)
        );

        e.target.value = amount;
    }
}

exports.DonateCore = DonateCore