import { isSet } from "./isset";
import EmblaCarousel from 'embla-carousel';
import Fade from 'embla-carousel-fade'
import { addControlBtnsClickHandlers } from './emblaCarouselControls';
import { addDotBtnsAndClickHandlers } from './emblaCarouselPagination'

/**
 * Initialises All Carousels on the site
 */
function initEmblaCarousels(){

    function initPlayCentreCarousel(){
        const playCentreCarouselNode = document.querySelector('.header--play-centre--js .embla');
        if(!isSet(playCentreCarouselNode)) return;
    
        const playCentreViewportNode = playCentreCarouselNode.querySelector('.embla__viewport');
        if(!isSet(playCentreViewportNode)) return;
    
        const playCentrePrevBtnNode = playCentreCarouselNode.querySelector('.embla__control--prev');
        const playCentreNextBtnNode = playCentreCarouselNode.querySelector('.embla__control--next');
    
        const playCentreEmblaApi = EmblaCarousel(
            playCentreViewportNode, 
            {}
        );
    
        if(!isSet(playCentrePrevBtnNode) || !isSet(playCentreNextBtnNode)) return;
        const removeplayCentreControlsClickHandlers = addControlBtnsClickHandlers(
            playCentreEmblaApi,
            playCentrePrevBtnNode,
            playCentreNextBtnNode
        );
    
        playCentreEmblaApi.on('destroy', removeplayCentreControlsClickHandlers);
    }

    function initHomepageCarousel(){
        const homeHeroCarouselNode = document.querySelector('.header--hero-banner--js .embla');
        if(!isSet(homeHeroCarouselNode)) return;

        const homeHeroViewportNode = homeHeroCarouselNode.querySelector('.embla__viewport');
        if(!isSet(homeHeroViewportNode)) return;

        const homeHeroDotsNode = homeHeroCarouselNode.querySelector('.embla__dots');
        if(!isSet(homeHeroDotsNode)) return;

        const homeHeroEmblaApi = EmblaCarousel(
            homeHeroViewportNode, 
            {
                loop: true,
                watchDrag: false,
                duration: 0
            },
            [Fade()]
        );

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            homeHeroEmblaApi,
            homeHeroDotsNode
        );

        homeHeroEmblaApi.on('destroy', removeDotBtnsAndClickHandlers);
    }
    
    initPlayCentreCarousel();
    initHomepageCarousel();
}

exports.initEmblaCarousels = initEmblaCarousels