import { isSet } from "./isset";

/**
 * Adds body class to check if JS is enabled
 * @returns {Boolean}
 */
function clipPathRandomizer(domNode, clipPathType){
    if(!isSet(domNode) || !isSet(clipPathType)) return;

    const suffixs = ["a", "b", "c", "d", "e", "f"];
    const suffix = suffixs[Math.floor(Math.random() * suffixs.length)];

    const className = 'has-clip-path--' + clipPathType + '--' + suffix;

    domNode.classList.add(className);
}

exports.clipPathRandomizer = clipPathRandomizer