import { isSet } from "../functions/isset";
import currency from "currency.js";

class CounterField {
    constructor(domNode){
        this.domNode = domNode;
        this.id = this.domNode.dataset.id;
        
        if(!isSet(this.id)){
            console.warn(this.domNode + ' requires a data-id value');
            return;
        }

        this.targetInput = this.domNode.querySelector('input');
        this.targetElement = document.querySelector('.counter--' + this.id +'--target');

        if(isSet(this.targetElement)){
            this.targetInput.addEventListener(
                'change',
                this.handleTargetUpdate.bind(this)
            );
        }

        this.increaseBtn = this.domNode.querySelector('.counter-increase');
        this.decreaseBtn = this.domNode.querySelector('.counter-decrease');

        if(!isSet(this.targetInput)){
            console.warn(this.domNode + ' requires a input element to target changes');
            return;
        }

        this.counterMaxAmount = this.targetInput.getAttribute('max');
        this.counterMinAmount = this.targetInput.getAttribute('min');
        this.changeEvent = new Event('change');

        this.targetInput.addEventListener(
            'change',
            this.handleTargetInputChange.bind(this)
        );

        if(!isSet(this.increaseBtn)){
            console.warn(this.domNode + ' requires a increase button (.counter-increase)');
            return;
        }

        this.increaseBtn.addEventListener(
            'click',
            this.handleIncreaseBtnClick.bind(this)
        );

        if(!isSet(this.decreaseBtn)){
            console.warn(this.domNode + ' requires a decrease button (.counter-decrease)');
            return;
        }

        this.decreaseBtn.addEventListener(
            'click',
            this.handleDecreaseBtnClick.bind(this)
        );

        this.init();
    }

    init(){
        const counterMaxAmount = this.counterMaxAmount;
        const counterMinAmount = this.counterMinAmount;
        this.setInputFilter(
            this.targetInput, 
            function(value) {
                return /^\d*$/.test(value) && (value === "" || (parseInt(value) >= parseInt(counterMinAmount) && parseInt(value) <= parseInt(counterMaxAmount)));
            }, 
            "Must be a number between 0 and " + counterMaxAmount
        );

        let value = this.targetInput.value;

        if(!value || value == null) value = 0;

        if(this.targetInput.value <= 0 ){
            this.decreaseBtn.setAttribute('disabled','');
        }
    }

    handleTargetInputChange(e){
        const counterMaxAmount = this.counterMaxAmount;
        if(e.target.value == counterMaxAmount){
            this.increaseBtn.setAttribute('disabled','');
        }else{
            this.increaseBtn.removeAttribute('disabled');
        }

        if(e.target.value == 0){
            this.decreaseBtn.setAttribute('disabled','');
        }else{
            this.decreaseBtn.removeAttribute('disabled');
        }
    }

    handleIncreaseBtnClick(e){
        let value = this.targetInput.value;

        if(!value || value == null)value = 0;

        let parsedValue = parseInt(value);

        if(parsedValue >= this.counterMaxAmount) return;

        const newValue = parsedValue + 1;

        this.targetInput.value = newValue;
        this.targetInput.setAttribute('value', newValue);
        this.targetInput.dispatchEvent(this.changeEvent);
    }

    handleDecreaseBtnClick(e){
        let value = this.targetInput.value;

        if(!value || value == null) value = 0;

        let parsedValue = parseInt(value);

        if(parsedValue <= 0) return;

        const newValue = parsedValue - 1;

        this.targetInput.value = newValue;
        this.targetInput.setAttribute('value', newValue);
        this.targetInput.dispatchEvent(this.changeEvent);
    }

    setInputFilter(targetInput, inputFilter, errMsg) {
        const changeEvent = this.changeEvent;
        [ "input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout" ].forEach(function(event) {
            targetInput.addEventListener(event, function(e) {
                
                if (inputFilter(this.value)) {
                    // Accepted value.
                    if ([ "keydown", "mousedown", "focusout" ].indexOf(e.type) >= 0) {
                        this.classList.remove("input-error");
                        this.setCustomValidity("");
                    }
                    
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                    targetInput.dispatchEvent(changeEvent);
                }
                else if (this.hasOwnProperty("oldValue")) {
                    // Rejected value: restore the previous one.
                    this.classList.add("input-error");
                    this.setCustomValidity(errMsg);
                    this.reportValidity();
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                    targetInput.dispatchEvent(changeEvent);
                }
                else {
                    // Rejected value: nothing to restore.
                    this.value = "0";
                    targetInput.dispatchEvent(changeEvent);
                }
            });
        });
    }

    handleTargetUpdate(){
        const counters = document.querySelectorAll('[data-id="' + this.id + '"]');
        let totalAmount = 0;

        counters.forEach(function (counter) {
            const targetInput = counter.querySelector('input');
            if(!isSet(targetInput)) return;

            const valueMultipler = targetInput.value;
            const valueMonetary = targetInput.dataset.value;

            const counterTotal = currency(valueMonetary).multiply(valueMultipler); 

            totalAmount = currency(totalAmount).add(counterTotal);
        });

        totalAmount = currency(totalAmount, {symbol: "£"}).format();

        if(currency(totalAmount).value == 0.00){
            this.targetElement.classList.add('none');
            this.targetElement.closest('button').setAttribute("disabled", "");
        }else{
            this.targetElement.classList.remove('none');
            this.targetElement.closest('button').removeAttribute("disabled");
        }

        this.targetElement.textContent = totalAmount;
    }
}

exports.CounterField = CounterField