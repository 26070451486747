import { DonateCore } from "./DonateCore";
import { isSet } from "../functions/isset";

class DonateForm extends DonateCore {
    constructor (domNode) {
        super(domNode);

        this.donateForm = this.domNode.closest('form');

        this.domNode.addEventListener(
            'donationAmountChange',
            this.handleDonateFormAmountChange.bind(this)
        );

        this.changeEvent = new Event("change");
        this.blurEvent = new Event("blur");

        this.redirectUrl = new URL(window.location.href);

        this.initDonateForm();
    }

    initDonateForm(){
        if(!isSet(this.donateForm)) return;

        this.amountField = this.donateForm.querySelector('.donation-amount');

        this.submitInput = this.donateForm.querySelector('input[type=submit]');

        this.amountField.addEventListener(
            'change',
            this.handleAmountFieldChange.bind(this)
        );

        this.formId = this.donateForm.id;

        this.redirectUrl.searchParams.forEach((value, key) => {

            if(key === "donationFrequency" && value === "monthly"){
                const tab = document.querySelector('#tab-' + value);
                if(isSet(tab)){
                    tab.click();
                }
            }


            if(key === "donationAmount"){
                let flag = false;

                this.presetAmountInputs.forEach( (input) => {
                    if(value){
                        if(input.value === value){
                            input.checked = true;
                            this.donationAmount = value;
                            this.domNode.dispatchEvent(this.donationAmountChange);
                            flag = true;
                        }
                    }
                });

                if(flag === false){
                    this.customAmountInput.value = value;
                    this.donationAmount = value;
                    
                    this.presetAmountInputs.forEach( (input) => {
                        if(input.checked){
                            this.previousSelectedPreset = input;
                        }
            
                        if(this.customAmountInput.value){
                            input.checked = false;
                        }
                    });

                    this.customAmountField.classList.add('is-selected');
                    this.domNode.dispatchEvent(this.donationAmountChange);
                }
            }
        });
    }

    handleDonateFormAmountChange(){
        this.amountField.value = this.donationAmount;
        this.amountField.dispatchEvent(this.changeEvent);
    }

    handleAmountFieldChange(){
        this.amountField.blur();
        this.amountField.dispatchEvent(this.blurEvent);

        if(this.formId === "CreditCardForm"){
            this.submitInput.value = "Complete £" + this.amountField.value + " Donation";
        }
    }
}

exports.DonateForm = DonateForm