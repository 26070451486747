import { Layer } from "./Layer";
import { isSet } from "../functions/isset";

class MobileMenu {
    constructor (domNode) {
        this.domNode = domNode;
        this.mobileMenuActive = false;

        this.mobileMenuDialog = this.domNode.querySelector('[role=menu]');

        this.mobileInitEvent = new Event('mobileMenuInitialised');
        this.mobileDestroyEvent = new Event('mobileMenuDestroyed');

        document.addEventListener(
            'DOMContentLoaded', 
            this.checkWindowWidth.bind(this)
        );

        window.addEventListener(
            'resize', 
            this.checkWindowWidth.bind(this)
        );
    }

    checkWindowWidth(){
        if (window.innerWidth < 900){
            this.initMobileMenu();
        } else{
            if(isSet(this.mobileMenuLayer)){
                this.mobileMenuLayer.closeLayer();
            }
            
            this.destroyMobileMenu();
        }
    }

    initMobileMenu(){
        if(this.mobileMenuActive === true) return;

        this.domNode.setAttribute("aria-hidden", "true");
        this.domNode.classList.add("layer");
        this.domNode.classList.add("layer--nav");
        this.mobileMenuDialog.setAttribute("role", "dialog");
        this.mobileMenuDialog.setAttribute("aria-modal", "true");

        this.mobileMenuLayer = new Layer(
            this.domNode,
            {}
        );

        window.dispatchEvent(this.mobileInitEvent);

        this.mobileMenuActive = true;
    }

    destroyMobileMenu(){
        if(this.mobileMenuActive === false) return;

        this.domNode.removeAttribute("aria-hidden");
        this.domNode.classList.remove("layer");
        this.domNode.classList.remove("layer--nav");
        this.mobileMenuDialog.removeAttribute("aria-modal");

        if(isSet(this.mobileMenuLayer)){
            this.mobileMenuLayer.destroy();
        }

        this.mobileMenuDialog.setAttribute("role", "menu");

        window.dispatchEvent(this.mobileDestroyEvent);

        this.mobileMenuActive = false;
    }
}

exports.MobileMenu = MobileMenu